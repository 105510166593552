import React from 'react';
import DashboardCard from './DashboardCard';
import SplashScreen from './SplashScreen';

function Dashboard(props) {

  function SplashOrDash() {
    if(props.user) {
      return <DashboardCard user={props.user}></DashboardCard>
    } else {
      return <SplashScreen></SplashScreen>
    }
  }

  return (
    <SplashOrDash></SplashOrDash>
  )
}

export default Dashboard;