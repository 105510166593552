import React from "react";

export interface Props {
    children: React.ReactNode;
}

export function PageContent(props: Props) {
    return (
        <div className="p-4 sm:ml-64">
            <div className="p-4 rounded-lg mt-14">
                {props.children}
            </div>
        </div>
    )
}
