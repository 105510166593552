import React, { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';


function GroceryList(props) {

  const [isPrinting, setIsPrinting] = useState(false);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    onBeforeGetContent: () => setIsPrinting(true),
    content: () => componentRef.current
    // onAfterPrint: () => setIsPrinting(false)
  });


  const printPage = async () => {
    handlePrint();
  }



  const handleCheckbox = (ingredient) => {
    ingredient.locallyChecked = !ingredient.locallyChecked;
  }

  const showIngredient = (isPrinting, isChecked) => {

    return isPrinting && isChecked
  }

  return (
    <div className="grocery-list">
      <div className="">
        <div className={isPrinting ? 'hidden' : 'grocery-list-content'}>
          {props.closeGroceryList &&
            <div>
              <button key="print" className='btn footer-btn' onClick={printPage}>Print</button>
              <button key="close" className='btn footer-btn' onClick={props.closeGroceryList}>Close</button>
              <div>Check ingredients to remove them from printed grocery list</div>
            </div>
          }
        </div>
        <div ref={componentRef} className="grocery-list-content">
          {props.ingredients && 
            props.ingredients.map(function(ingredient, i) {
              if(!ingredient.checked) {
                let uom = ingredient.uom;
                if (uom === "each") {
                  uom = "";
                }
                return (
                <div className={showIngredient(isPrinting, ingredient.locallyChecked) ? 'hidden' : ''} key={ingredient.name}>
                  <label className={isPrinting ? 'printer-friendly-grocery-list' : 'checkbox-label'}>
                    <input type="checkbox" className={isPrinting ? 'hidden' : ''} onClick={handleCheckbox.bind(this, ingredient)}></input>
                    <span>{ingredient.quantity + " " + (uom||"") + " " + ingredient.name}</span>
                  </label>
                </div>
                )
              }
              return null;
            })}
        </div>
      </div>
    </div>
  );
}

export default GroceryList;