import React from "react";
import {TruckIcon} from "../icons/TruckIcon";
import {Avatar, Dropdown} from "flowbite-react";
import {LoginModal} from "./LoginModal";
import store from "store";
import {UserContext} from "../App";
import {RegisterModal} from "./RegisterModal";

export function Navbar() {
    const user = React.useContext(UserContext);
    const [isLoginModalOpen, setIsLoginModalOpen] = React.useState(false);
    const [isRegisterModalOpen, setIsRegisterModalOpen] = React.useState(false);
    const logout = () => {
        store.remove('user');
        window.location.reload();
    };

    return (
        <nav
            className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
            <div className="px-3 py-3 lg:px-5 lg:pl-3">
                <div className="flex items-center justify-between">
                    <div className="flex items-center justify-start">
                        <button data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar"
                                aria-controls="logo-sidebar" type="button"
                                className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                            <span className="sr-only">Open sidebar</span>
                            <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path clipRule="evenodd" fillRule="evenodd"
                                      d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                            </svg>
                        </button>
                        <a href="/" className="flex ml-2 md:mr-24">
                            {/*<Logo/>*/}
                            <span
                                className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-white">Beef Universe</span>
                        </a>
                    </div>
                    <div className="flex items-center">
                        <div className="flex md:order-2">
                            <Dropdown
                                arrowIcon={false}
                                inline={true}
                                label={<Avatar rounded={true} placeholderInitials={user?.username.charAt(0)}/>}
                            >
                                {user ? (
                                    <>
                                        <Dropdown.Header>
                                        <span className="block text-sm">
                                            {user?.username}
                                        </span>
                                            <span className="block truncate text-sm font-medium">
                                            {user?.email}
                                        </span>
                                        </Dropdown.Header>
                                        <Dropdown.Item>
                                            Dashboard
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                            Settings
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                            Earnings
                                        </Dropdown.Item>
                                        <Dropdown.Divider/>
                                        <Dropdown.Item onClick={logout}>
                                            Sign out
                                        </Dropdown.Item>
                                    </>
                                ) : (
                                    <>
                                        <Dropdown.Item onClick={()=>setIsLoginModalOpen(true)}>
                                            Sign in
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={()=>setIsRegisterModalOpen(true)}>
                                            Register
                                        </Dropdown.Item>
                                        <LoginModal closeModal={()=>setIsLoginModalOpen(false)} isOpen={isLoginModalOpen}/>
                                        <RegisterModal closeModal={()=>setIsRegisterModalOpen(false)} isOpen={isRegisterModalOpen}/>
                                    </>
                                )}
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}