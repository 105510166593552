import React, { useState, useEffect, useRef } from 'react';
import NewRecipeCard from './NewRecipeCard';

import store from "store";

import axios from "axios";

function RecipeModal(props) {
  const token = "Token " + store.get('token');

  const [name, setName] = useState(props.name);
  const [showModal, setShowModal] = useState(false);

  const textInput = useRef(null);

  useEffect(() => {
    textInput.current?.focus();
  }, []);

  function closeParentModal() {
    props.closeModal();
  }

  function handleChange(name) {
    setName(name.target.value);
  }

  function handleKeypress(event) {
    if(event.key === 'Enter') {
      event.preventDefault();
      if(props.name) {
        editMeal();
      } else {
        saveMeal();
      }

    }
  }

  function saveMeal() {
    axios.post('/api/meals/add', 
    {
      name,
      date: props.date
    },
    {
      headers: {Authorization: token},
        
    })
    .then(() => {
      // TODO: Not refresh page
      window.location.reload();
    })
    .catch(function () {
      // handle error
    });
  }

  function editMeal() {
    axios.post('/api/meals/edit', 
    {
      newName: name,
      date: props.date,
      dayOfWeek: props.dayOfWeek,
      weekId: props.weekId,
      mealId: props.mealId
    },
    {
      headers: {Authorization: token},
        
    })
    .then(() => {
      // TODO: Not refresh page
      window.location.reload();
    })
    .catch(function () {
      // handle error
    });
  }

  function SaveEditButtons() {
    if(props.name) {
      return <button className="btn footer-btn" onClick={editMeal}>Save</button>
    } else {
      return <button className="btn footer-btn" onClick={saveMeal}>Save</button>
    }
  }




  function closeModal() {
    setShowModal(false);
  }

  return (
    <div className="mealplan-modal">
      <div className="blur" onClick={closeParentModal}></div>
      <div className="card modal-card">
        <div>
          <span>Name meal</span>
        </div>
        <div>
          <input className="mealplan-modal-input" type="text" value={name} onChange={handleChange} ref={textInput} onKeyPress={handleKeypress}></input>
          <SaveEditButtons/>
          <button className="footer-btn btn" onClick={closeParentModal}>Cancel</button>
        </div>
      </div>
      {showModal &&
          
          <div className="recipe-modal">
            <div className="blur blur-jr" onClick={closeModal}></div>
            <div className="big-modal-card">
              <NewRecipeCard date={props.date} dayOfWeek={props.dayOfWeek} weekId={props.weekId} mealId={props.mealId} isPublic="true" displayType="modal"></NewRecipeCard>
            </div>
          </div>
        }
    </div>
  )
}

export default RecipeModal;