import React, { useState } from 'react';
import GroceryList from './GroceryList';
import MealPrepDay from './MealPrepDay';
import WeekPicker from './WeekPicker';
import { format, isValid } from 'date-fns'



import axios from "axios";

import store from "store";

function MealPrepCard(props) {
  const token = "Token " + store.get('token');
  let i=0;

  const [ingredients, setIngredients] = useState([]);
  const [showModal, setShowModal] = useState(false);
  function createRecipe() {
    window.location.href ="/recipe/new";
  }
  
  function showGroceryList() {
    setShowModal(true);
    axios.get('/api/meals/list', 
    {
      headers: {Authorization: token},
        
    })
    .then((res) => {
      // TODO: Not refresh page
      // window.location.reload();
      setIngredients(res.data.ingredients)
      // console.log("ingredients", res.data.ingredients)
    })
    .catch(function () {
      // handle error
    });
    
  }
  function closeGroceryList() {
    setShowModal(false);
  }
  function updateParent(week) {
    props.updateParent(week);
  }

  function formattedDateString() {
    let startDate = new Date(props.week.startDate);
    // let daysElapsed = (today+7)%7;
    // startDate.setDate(startDate.getDate()-(daysElapsed+(7*props.filter)));
    let endDate = new Date(startDate.getTime());
    endDate.setDate(endDate.getDate()+6)
    
    if(isValid(startDate) && isValid(endDate)) {
      return format(startDate, "iii MMM d") + " - " + format(endDate, "iii MMM d"); 
    } else {
      console.log("startDate", startDate)
      console.log("endDate", endDate)
    }
    // return startDate.toDateString() + "-" + endDate.toDateString(); 
  }
  return (
    <div className="card meal-prep-card">
      <div>
        <button className="btn footer-btn" onClick={createRecipe}>Create Recipe</button>
        <button className="btn footer-btn" onClick={showGroceryList}>Get Grocery List</button>
      </div>
      <div>
        <div className="date-string">{formattedDateString()}</div>
        <WeekPicker updateParent={updateParent}></WeekPicker>
      </div>
      <MealPrepDay day="Sunday" weekId={props.week._id} dayOfWeek={i++} startDate={props.week.startDate} meals={props.week["sunday"]}></MealPrepDay>
      <MealPrepDay day="Monday" weekId={props.week._id} dayOfWeek={i++} startDate={props.week.startDate} meals={props.week["monday"]}></MealPrepDay>
      <MealPrepDay day="Tuesday" weekId={props.week._id} dayOfWeek={i++} startDate={props.week.startDate} meals={props.week["tuesday"]}></MealPrepDay>
      <MealPrepDay day="Wednesday" weekId={props.week._id} dayOfWeek={i++} startDate={props.week.startDate} meals={props.week["wednesday"]}></MealPrepDay>
      <MealPrepDay day="Thursday" weekId={props.week._id} dayOfWeek={i++} startDate={props.week.startDate} meals={props.week["thursday"]}></MealPrepDay>
      <MealPrepDay day="Friday" weekId={props.week._id} dayOfWeek={i++} startDate={props.week.startDate} meals={props.week["friday"]}></MealPrepDay>
      <MealPrepDay day="Saturday" weekId={props.week._id} dayOfWeek={i++} startDate={props.week.startDate} meals={props.week["saturday"]}></MealPrepDay>

      { showModal && 
        <GroceryList ingredients={ingredients} closeGroceryList={closeGroceryList}></GroceryList>
      }
    </div>
  )
}

export default MealPrepCard;