import React from 'react';


class Terms extends React.Component {
 
  render() {
    return (
        <div className="card summary-card">
          <h1>Terms of Services</h1>
          <div>
              By accessing this web site, you are agreeing to be bound by these web site Terms and Conditions of Use, all
              applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws.
              If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials
              contained in this web site are protected by applicable copyright and trade mark law.

          </div>
          <div>
              <h2>1. Use License</h2>
              Permission is granted to temporarily download one copy of the materials (information or software) on Beef
              Universe's web site for personal, non-commercial transitory viewing only. This is the grant of a license, not a
              transfer of title, and under this license you may not:
              modify or copy the materials
              use the materials for any commercial purpose, or for any public display (commercial or non-commercial)
              attempt to decompile or reverse engineer any software contained on Beef Universe's web site
              remove any copyright or other proprietary notations from the materials or
              transfer the materials to another person or "mirror" the materials on any other server.
              This license shall automatically terminate if you violate any of these restrictions and may be terminated by Beef
              Universe at any time. Upon terminating your viewing of these materials or upon the termination of this license, you
              must destroy any downloaded materials in your possession whether in electronic or printed format.
          </div>
          <div>
              <h2>2. Disclaimer</h2>
              The materials on Beef Universe's web site are provided "as is". Beef Universe makes no warranties, expressed or
              implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or
              conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or
              other violation of rights. Further, Beef Universe does not warrant or make any representations concerning the
              accuracy, likely results, or reliability of the use of the materials on its Internet web site or otherwise relating
              to such materials or on any sites linked to this site.
          </div>

          <div>
              <h2>3. Limitations</h2>
              In no event shall Beef Universe or its suppliers be liable for any damages (including, without limitation, damages
              for loss of data or profit, or due to business interruption,) arising out of the use or inability to use the
              materials on Beef Universe's Internet site, even if Beef Universe or a Beef Universe authorized representative
              has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow
              limitations on implied warranties, or limitations of liability for consequential or incidental damages, these
              limitations may not apply to you.
          </div>

          <div>
              <h2>4. Revisions and Errata</h2>
              The materials appearing on Beef Universe's web site could include technical, typographical, or photographic errors.
              Beef Universe does not warrant that any of the materials on its web site are accurate, complete, or current. Beef
              Universe may make changes to the materials contained on its web site at any time without notice. Beef Universe does
              not, however, make any commitment to update the materials.
          </div>

          <div>
              <h2>5. Links</h2>
              Beef Universe has not reviewed all of the sites linked to its Internet web site and is not responsible for the
              contents of any such linked site. The inclusion of any link does not imply endorsement by Beef Universe of the
              site. Use of any such linked web site is at the user's own risk.
          </div>

          <div>
              <h2>6. Site Terms of Use Modifications</h2>
              Beef Universe may revise these terms of use for its web site at any time without notice. By using this web site you
              are agreeing to be bound by the then current version of these Terms and Conditions of Use.
          </div>

          <div>
              <h2>7. Governing Law</h2>
              Any claim relating to Beef Universe's web site shall be governed by the laws of the State of Wisconsin without
              regard to its conflict of law provisions.
          </div>

          <div>
              <h2>8. Refunds</h2>
              If you are not 100% satisfied with your purchase, within the first seven days, contact nomnomtracker@gmail.com to
              request a full refund of your most recent subscription bill.
          </div>
          <div>
              Contact Us
              nomnomtracker@gmail.com
          </div>
        </div>
    )
  }

  

  
}

export default Terms;