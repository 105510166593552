import React from 'react';


class SplashScreen extends React.Component {
 
  render() {
    return (
        <div className="card summary-card">
          <h1>Beef Universe is still under active development. Use at your own risk.</h1>
          <div><a href="/privacy">privacy</a></div>
          <div><a href="/terms">terms</a></div>
        </div>
    )
  }

  

  
}

export default SplashScreen;