import React from "react";
import {TruckIcon} from "../icons/TruckIcon";

export interface Props {
    name: string;
    link: string;
    icon?: React.ReactNode;
}
export function SidebarItem(props: Props) {
    return (
        <li>
            <a href={props.link}
               className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                {props.icon ? props.icon : <TruckIcon/>}
                <span className="ml-3">{props.name}</span>
            </a>
        </li>
    )
}