import React, { useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import MealPrepCard from './MealPrepCard';

import axios from "axios";

import store from "store";

function MealPrep() {

  const [week, setWeek] = useState({});
  const [weeksAgo, setWeeksAgo] = useState(0);
  let {weekId} = useParams();
  let token = "Token " + store.get('token');

  function getWeek(token, weeksAgo) {
    axios.get('/api/meals', {
      headers: {
        Authorization: token,
        weeksAgo
      }
    })
    .then((response) => {
      let week = response.data.week;
      setWeek(week)

    })
    .catch(function (error) {
      // handle error
      console.log("error")
      console.log(error)
    });
  }
  useEffect(() => {


    getWeek(token, 0);
  }, [token, weekId])

  function handleWeek(week) {
    const newWeek = weeksAgo+week;
    setWeeksAgo(newWeek);
    getWeek(token, newWeek);

  }
  



  return (
    <div>
      <MealPrepCard updateParent={handleWeek} week={week}></MealPrepCard>
    </div>
  )
}

export default MealPrep;