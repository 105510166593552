import React from 'react';
import NewRecipeCard from './NewRecipeCard';

function NewRecipe() {

  return (
    <div>
      <NewRecipeCard isPublic="true" isStandalone="true"></NewRecipeCard>
    </div>
  )
}

export default NewRecipe;