import React from 'react';
import Header from './Header';
import axios from "axios";

class ResetPassword extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      token: "",
      password: ""
    }
    
    this.reset = this.reset.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    
  }

  componentDidMount() {
    if(this.props.match != null) {
      this.setState({
        token: this.props.match.params.token
      })
      // this.resetToken = this.props.match.params.token
    } 
  }
 
  reset() {
    //Post reset route
    axios.post('/api/users/reset', { 
        "token": this.state.token,
        "newPassword": this.state.password
    })
    // .then((response) => {
    //   //Navigate home
    // })
    // .catch(function (error) {
    //   // handle error
    // });
  }

  handlePasswordChange(event) {
    this.setState({password: event.target.value});
  }

  render() {
    return (
        <div>
          <Header forceLogout="true"></Header>
          <div className='card reset-password-card'>
            <h2 className="reset-modal">Reset</h2>
            <div className="login-row">New Password: <input className="rounded-text-input" type="password" value={this.state.password} onChange={this.handlePasswordChange}/></div>
            <button className="btn footer-btn" onClick={this.reset}>Reset Password</button>
          </div>
        </div>
    )
  }

  

  
}

export default ResetPassword;