import React, { useState } from 'react';
import {addDays} from 'date-fns'
import RecipeModal from './RecipeModal';
import MealPrepMeal from './MealPrepMeal';
import { format } from 'date-fns'







function MealPrepDay(props) {

  const [displayModal, setDisplayModal] = useState(false);
  let date, dateString;
  if(props.startDate) {
    date = new Date(props.startDate)
    date = addDays(date, props.dayOfWeek)
    console.log(date)
    dateString = format(date, "M/d")
  }
  function displayButtons(props) {
    if(props.meals && props.meals.length > 0) {
      return props.meals.map(function(meal, i) {
        return <MealPrepMeal key={i} date={date} weekId={props.weekId} dayOfWeek={props.dayOfWeek} mealId={meal._id} name={meal.name} recipeId={meal.recipeId}></MealPrepMeal>
      })
    }
  }

  
  function openModal() {
    setDisplayModal(true);
  }

  function closeModal() {
    setDisplayModal(false);
  }

  return (
    <div className="meal-prep-day">
      <h3>{props.day} - {dateString}</h3>
      <div>
          { displayButtons(props) }
          <div>
            <button className="btn footer-btn" onClick={openModal}>Add Meal</button>
          </div>
          { displayModal &&
            <RecipeModal date={date} closeModal={closeModal}></RecipeModal>
          }
      </div>

    </div>
  )
}

export default MealPrepDay;