import React from 'react';

import "react-datepicker/dist/react-datepicker.css";

import store from "store";

import axios from "axios";

class NewRecipeCard extends React.Component {
  constructor(props){
    super(props);
    this.nameName = "";
    let steps = [""];
    let ingredients = [{
        name: "",	
        quantity: "",	
        uom: ""
      }]
    if(props.steps) {
      steps = props.steps.map(function(step) {
        return step.text;
      })
    }

    if(props.ingredients) {
      ingredients = props.ingredients
    }
    
    this.state = {
      isPublic: props.isPublic,
      showAddPurchaseCard: true,
      recipeName: props.name || "",
      date: new Date(),
      ingredients,
      steps
    } 
    this.token = "Token " + store.get('token');
    this.handleKeypress = this.handleKeypress.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.deleteRecipe = this.deleteRecipe.bind(this);
    this.handleOpenAddPurchase = this.handleOpenAddPurchase.bind(this);
    this.handleCloseAddPurchase = this.handleCloseAddPurchase.bind(this);
    this.toggleVisibility = this.toggleVisibility.bind(this);
    this.importFromClipboard = this.importFromClipboard.bind(this);

  }

  // handleRecipeNameChange(event) {
  //   this.setState({recipeName: event.target.value});
  // }

  componentDidUpdate() {
    if(this.nameName) {
      this.refs[this.nameName].focus();
      this.nameName = "";
    }

  }
  handleRecipeNameChange = recipeName => {
    this.setState({
      recipeName: recipeName.target.value
    });
  };
  handleDateChange = date => {
    this.setState({
      date
    });
  };

  handleNameChange(i, event) {
    let ingredients = [...this.state.ingredients];
    ingredients[i].name = event.target.value;
    this.setState({ ingredients });
  }
  handleQuantityChange(i, event) {
    let ingredients = [...this.state.ingredients];
    ingredients[i].quantity = event.target.value;
    this.setState({ ingredients });
  }
  handleUOMChange(i, event) {
    let ingredients = [...this.state.ingredients];
    ingredients[i].uom = event.target.value;
    this.setState({ ingredients });
  }
  handleStepTextChange(i, event) {
    let steps = this.state.steps;
    steps[i] = event.target.value;
    this.setState({ steps });
  }

  addIngredientClick(){
    const ingLen = this.state.ingredients.length;
    this.setState(prevState => ({ ingredients: [...prevState.ingredients, {} ]}))
    return ingLen;
  }
  addStepClick(){
    const ingLen = this.state.steps.length;
    this.setState(prevState => ({ steps: [...prevState.steps, "" ]}))
    return ingLen;
  }

  handleKeypress(event) {
    if(event.key === 'Enter') {
      event.preventDefault();
      if(event.target.className === "ingredient-form") {
        let ingLen = this.addIngredientClick();
        this.nameName = "ing_name_"+ingLen;
      } else if(event.target.className === "step-form") {
        let ingLen = this.addStepClick();
        this.nameName = "step_name_"+ingLen;

      }
      
    }
  }

  handleOpenAddPurchase() {
    this.setState({showAddPurchaseCard: true})
  }
  
  handleCloseAddPurchase() {
    this.setState({showAddPurchaseCard: false})
  }

  deleteRecipe() {
    if(window.confirm("Are you sure you want to delete this recipe? (This cannot be undone)")) {
      axios.post('/api/recipes/delete', 
      { 
        id: this.props.recipeId
      },
      {
        headers: {Authorization: this.token},
          
      })
      .then(function() {
        window.location.href ="/myrecipes";
      })
      .catch(function (error) {
        // handle error
      });
    }
  }

  handleSubmit(event) {
    let isStandalone = this.props.isStandalone
    let route;
    if(this.props.hideEditForm) {
      route = "/api/recipes/update";
    } else {
      route = "/api/recipes/add";
    }
    let ingredients = this.state.ingredients;
    let steps = this.state.steps;
    let hasValidAttributes = false;
    ingredients.forEach(function (ingredient) {
      if((Object.keys(ingredient).length > 0) && (ingredient.name !== "" || ingredient.quantity !== "" || ingredient.uom !== "")) {
        hasValidAttributes = true
      }     
    });
    let date = new Date()
    let year = date.getFullYear()
    let month = date.getMonth()
    let day = date.getDate()
    let offset = date.getTimezoneOffset()
    date = new Date(Date.UTC(year, month, day, 0, offset, 1))
    if(hasValidAttributes) {
      
      axios.post(route, 
      {
        name: this.state.recipeName,
        date,
        ingredients: ingredients,
        steps: steps,
        isPublic: this.state.isPublic,
        recipeId: this.props.recipeId
      },
      {
        headers: {Authorization: this.token},
          
      })
      .then((res) => {
        if(isStandalone) {
          let recipeId = res.data.id;
          window.location.href =`/recipe/${recipeId}`;
        } else {
          window.location.reload();
        }
      })
      .catch(function (err) {
        // handle error
        // console.log(err)
      });
    } else {
      alert("Please make sure you submit at least one item");
    }
    event.preventDefault();
  }

  removeIngredient(i){
    let ingredients = [...this.state.ingredients];
    ingredients.splice(i,1);
    this.setState({ ingredients });
  }
  removeStep(i){
    let steps = [...this.state.steps];
    steps.splice(i,1);
    this.setState({ steps });
  }

  createIngredients(){
    return this.state.ingredients.map((el, i) => 
      <div key={i}>
        <input type="text" className="ingredient-form ingredient-form-left" ref={"ing_name_"+i} placeholder="name" value={el.name||''} onChange={this.handleNameChange.bind(this, i)} />
        <input type="text" className="ingredient-form" placeholder="quantity" value={el.quantity||''} onChange={this.handleQuantityChange.bind(this, i)} />
        <input type="text" className="ingredient-form" placeholder="uom" value={el.uom||''} onChange={this.handleUOMChange.bind(this, i)} />
        <input type='button' className="btn remove-btn" value='remove' onClick={this.removeIngredient.bind(this, i)}/>
      </div>          
    )
 }
 
 createSteps(){
    return this.state.steps.map((el, i) => 
      <div key={i}>
        <input type="text" className="step-form" ref={"step_name_"+i} placeholder={"step "+ (i+1)} value={el||''} onChange={this.handleStepTextChange.bind(this, i)} />
        <input type='button' className="btn remove-btn" value='remove' onClick={this.removeStep.bind(this, i)}/>
      </div>          
    )
 }

  toggleVisibility(event) {
    event.preventDefault();
    this.setState({isPublic: !this.state.isPublic})
  }

  importFromClipboard() {
    navigator.clipboard.readText()
      .then(text => {
        const json = JSON.parse(text)
        this.setState({
          recipeName: json.name,
          steps: json.steps,
          ingredients: json.ingredients
        })
        console.log(json)
      })
      .catch(err => {
        console.error('Failed to read clipboard contents: ', err);
      });
  }

  createVisibilityButtons() {
    if(!this.state.isPublic) {
      return <button className="btn footer-btn" onClick={this.toggleVisibility}>make public</button>
    } else {
      return <button className="btn footer-btn" onClick={this.toggleVisibility}>make private</button>
    }
  }

  createHeaderButtons() {
    if(this.props.hideEditForm) {
      return (
      <div>
        <button className="btn footer-btn" onClick={this.props.hideEditForm}>Cancel</button>
        <button className="btn footer-btn red-footer-btn" onClick={this.deleteRecipe}>Delete</button>
      </div>
      );
    } else {
      return <button id="import" className="btn footer-btn" onClick={this.importFromClipboard}>Import from Clipboard</button>
    }
  }
 
  render() {
    return (
        <div>
          <div className="card edit-recipe-card">
            {this.createHeaderButtons()}
            <form onSubmit={this.handleSubmit} onKeyPress={this.handleKeypress}>
              {this.createVisibilityButtons()}
              <div className="purchase-card-header">
                <span className="recipe-name-label">Recipe Name: </span><input type="text" className="recipe-name" value={this.state.recipeName} onChange={this.handleRecipeNameChange} />
              </div>
              <h3>Ingredients</h3>
              {this.createIngredients()}
              <input type="button" className="btn footer-btn" value="add more" onClick={this.addIngredientClick.bind(this)}/>
              <h3>Steps</h3>
              {this.createSteps()}
              <input type="button" className="btn footer-btn" value="add more" onClick={this.addStepClick.bind(this)}/>
              <input type="submit" className="btn footer-btn" value="submit" />
            </form>
          </div>
        </div>
    )
  }

  

  
}

export default NewRecipeCard;