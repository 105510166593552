import { useState, useEffect } from 'react';

const useGoogleMapsApi = () => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (window.google) {
            setIsLoaded(true);
            return;
        }

        const handleApiLoaded = () => {
            setIsLoaded(true);
        };

        window.addEventListener('googleMapsApiLoaded', handleApiLoaded);

        return () => {
            window.removeEventListener('googleMapsApiLoaded', handleApiLoaded);
        };
    }, []);

    return isLoaded;
};

export default useGoogleMapsApi;

