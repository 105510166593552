import React from 'react';
import Modal from 'react-modal';
import LoginModal from './LoginModal';
import LegacySidebar from './LegacySidebar';
import store from "store";

import axios from "axios";

const LOGIN = 1;
const CREATE_ACC = 2

Modal.setAppElement('#root');

export class Header extends React.Component {


  constructor(props){
    super(props)
    if (props.forceLogout === "true") {
      this.logout();
    }
    let loggedIn
    this.title = "Beef Universe"
    //Check to see if logged in
    // var user = store.get('user');
    const token = "Token " + store.get('token');
    if(token && token !== "Token undefined") {
      this.current(token);

      this.token = token;
      loggedIn = true;
    } else {
      loggedIn = false;
    }

    this.state = {
      loggedIn: loggedIn,
      userObj: "",
      showLoginForm: false,
      showRegisterForm: false,
      showResetPasswordForm: false,
      showChangePasswordForm: false,
      newUsername: "",
      email: "",
      password: "",
      newPassword: "",
      modalIsOpen: false,
      menuIsOpen: false,
      width: 0,
      height: 0
    }





    this.toggleLoginForm = this.toggleLoginForm.bind(this);
    this.toggleRegisterForm = this.toggleRegisterForm.bind(this);
    this.toggleResetForm = this.toggleResetForm.bind(this);
    this.toggleChangeForm = this.toggleChangeForm.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
    this.handleNewUsernameChange = this.handleNewUsernameChange.bind(this);
    this.login = this.login.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.register = this.register.bind(this);
    this.current = this.current.bind(this);
    this.logout = this.logout.bind(this);
    this.change = this.change.bind(this);
    this.openModal = this.openModal.bind(this);
    this.menuOpen = this.menuOpen.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openLoginModal = this.openLoginModal.bind(this);
    this.openCreateAccountModal = this.openCreateAccountModal.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);



  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  login() {
    //Post login route
    const email = this.state.email;
    const password = this.state.password;
    axios.post('/api/users/login', {
      user: {
        email: email,
        password: password,
      }
    })
    .then((response) => {
      // TODO: Not refresh page
      window.location.reload();
      // alert(response.data.user.email)
      store.set('token', response.data.user.token)
      this.setState({
        userObj: response.data.user,
        username: response.data.user.username,
        token: response.data.user.token,
        loggedIn: true,
        showLoginForm: false,
        modalIsOpen: false
      });

      this.handleUserChange(response.data.user);
    })
    .catch(function (error) {
      // handle error
    });
  }

  forgotPassword() {
    //Post forgotPassword route
    const email = this.state.email;
    // var password = this.state.password;
    axios.post('/api/users/forgot', {
      user: {
        email: email,
      }
    })
    .then((response) => {
      // alert(response.data.user.email)
      store.set('token', response.data.user.token)
      this.setState({
        userObj: response.data.user,
        username: response.data.user.username,
        token: response.data.user.token,
        loggedIn: true,
        showLoginForm: false,
        modalIsOpen: false
      })
    })
    .catch(function (error) {
      // handle error
    });
  }
  register() {
    //Post register route
    const email = this.state.email;
    const password = this.state.password;
    const username = this.state.newUsername;
    axios.post('/api/users/register', {
      user: {
        email: email,
        password: password,
        username: username,
        modalIsOpen: false
      }
    })
    .then((response) => {
      this.setState(state => ({
        showRegisterForm: false
      }));
    })
    .catch(function (error) {
      // handle error
    });
  }

  async current(token) {
    try{
      const response = await axios.get('/api/users/current', {
        headers: {Authorization: token}
      })
      console.log(response)
      store.set('user', response.data.user)
      this.setState({
        userObj: response.data.user,
        // token: response.data.user.token,
        username: response.data.user.displayName || response.data.user.username,
        loggedIn: true,
        showLoginForm: false
      });
      this.handleUserChange(response.data.user);
    } catch (error) {
      if (error.response && error.response.status === 401) {
          this.logout();
      }
    }

  }

  logout() {
    const token = "Token " + store.get('token');
    if(token && token !== "Token undefined") {
      store.remove('token');
      window.location.reload();
      this.setState({
        loggedIn: false
      });
    }
  }

  change() {
    //Post change route
    axios.post('/api/users/change',
    {
      "token": this.token,
      "oldPassword": this.state.password,
      "newPassword": this.state.newPassword},
    {
      headers: {Authorization: this.token},

    })
    .then((response) => {
      //Navigate home
    })
    .catch(function (error) {
      // handle error
    });
  }
  toggleLoginForm() {
    this.setState(state => ({
      modalIsOpen: !state.showLoginForm,
      showLoginForm: !state.showLoginForm,
      showRegisterForm: false,
      showResetPasswordForm: false,
      showChangeForm: false,
    }));
  }
  toggleRegisterForm() {
    this.setState(state => ({
      modalIsOpen: !state.showRegisterForm,
      showRegisterForm: !state.showRegisterForm,
      showLoginForm: false,
      showResetPasswordForm: false,
      showChangeForm: false,
    }));
  }
  toggleResetForm() {
    this.setState(state => ({
      modalIsOpen: !state.showResetPasswordForm,
      showResetPasswordForm: !state.showResetPasswordForm,
      showLoginForm: false,
      showRegisterForm: false,
      showChangeForm: false,
    }));
  }
  toggleChangeForm() {
    this.setState(state => ({
      modalIsOpen: !state.showChangeForm,
      showChangeForm: !state.showChangeForm,
      showLoginForm: false,
      showRegisterForm: false,
      showResetPasswordForm: false,
    }));
  }
  handleEmailChange(event) {
    this.setState({email: event.target.value});
  }
  handlePasswordChange(event) {
    this.setState({password: event.target.value});
  }
  handleNewPasswordChange(event) {
    this.setState({newPassword: event.target.value});
  }
  handleNewUsernameChange(event) {
    this.setState({newUsername: event.target.value});
  }

  openModal() {
    this.setState({modalIsOpen: true});
  }
  menuOpen() {
    console.log("Open");
    this.setState({menuIsOpen: true});
  }

  closeMenu() {
    this.setState({menuIsOpen: false});
  }

  goHome() {
    window.location.href ="/";
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
  }

  closeModal() {
    this.setState({
      modalIsOpen: false,
      showLoginForm: false,
      showRegisterForm: false,
      showResetPasswordForm: false,
      showChangeForm: false,

    });
  }

  handleUserChange(user) {
    this.props.updateParent({user: user});
  }

  foodBudget() {
    window.location.href ="/budget";
  }

  mealPlanning() {
    window.location.href ="/mealprep";
  }

  myRecipes() {
    window.location.href ="/myrecipes";
  }

  newRecipe() {
    window.location.href ="/recipe/new";
  }

  openLoginModal() {
    this.setState({
      modalIsOpen: true,
      modalType: LOGIN
    });
  }

  openCreateAccountModal() {
    this.setState({
      modalIsOpen: true,
      modalType: CREATE_ACC
    });
  }



  render() {

    return (
      <div className="header-container">
        {this.state.modalIsOpen &&
          <LoginModal modalType={this.state.modalType} closeModal={this.closeModal}></LoginModal>
        }

        <div className="header">
          <div className="double-header">
            <div>
              <div className={this.state.loggedIn && this.state.width > 768 ? 'header-links' : 'hidden'}>
                <a href="/budget">Food Budget</a>
                <a href="/mealprep">Meal Prep</a>
                <a href="/recipes">Find Recipes</a>
                <a href="/myrecipes">My Recipes</a>
                <a href="/recipe/new">Add Recipe</a>
              </div>
              <div className={this.state.loggedIn && this.state.width <= 768 ? 'header-hamburger' : 'hidden'}>
              <LegacySidebar menuOpen={this.state.menuOpen} loggedIn={this.state.loggedIn} login={this.toggleLoginForm} register={this.toggleRegisterForm}></LegacySidebar>
              </div>
              <div className={this.state.loggedIn ? 'login-links' : 'hidden'}>
                {
                  this.state.username &&
                  this.state.username + " | "
                }
                <span className="link-underline" onClick={this.logout}>Logout</span>
              </div>
              <div className={this.state.loggedIn ? 'hidden' : 'login-links'}>
                <span className="link-underline" onClick={this.openCreateAccountModal}>Create Account</span>
                &nbsp;|&nbsp;
                <span className="link-underline" onClick={this.openLoginModal}>Login</span>

              </div>
            </div>
          </div>
          <div className={this.state.loggedIn ? 'logo' : 'logo logo-logged-out'}>
            <a className="link" href="/">{this.title}</a>
          </div>
        </div>
      </div>
    );
  }

}


export default Header;