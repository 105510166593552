import React, { useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import RecipeCard from './RecipeCard';
import NewRecipeCard from './NewRecipeCard';

import store from "store";

import axios from "axios";

function Recipe() {

  const [recipe, setRecipe] = useState("");
  const [isFavorited, setIsFavorited] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  let {recipeId} = useParams();
  let token = "Token " + store.get('token');

  function toggleEditForm() {
    setShowEditForm(!showEditForm);
  }
  useEffect(() => {
    function getRecipe(token) {
      axios.get('/api/recipes/'+recipeId, {
        headers: {Authorization: token}
      })
      .then((response) => {
        let recipe = response.data.recipe;
        setIsFavorited(response.data.isFavorited);
        recipe.ingredients.forEach(ingredient => {
          ingredient.checked = false;
        });
        let steps = []
        recipe.steps.forEach(step => {
          steps.push({text: step, checked: false});
        });
        recipe.steps = steps;
        setRecipe(recipe)
  
      })
      .catch(function (error) {
        // handle error
        console.log("error")
        console.log(error)
      });
    }

    getRecipe(token);
  }, [token, recipeId])
  



  return (
    <div>
      {!showEditForm &&
        <RecipeCard recipeId={recipe._id} name={recipe.name} steps={recipe.steps} ingredients={recipe.ingredients} favorites={recipe.favorites} isFavorited={isFavorited} showEditForm={toggleEditForm}></RecipeCard>
      }
      {showEditForm &&
        <NewRecipeCard recipeId={recipe._id} name={recipe.name} steps={recipe.steps} ingredients={recipe.ingredients} favorites={recipe.favorites} isFavorited={isFavorited} hideEditForm={toggleEditForm} isPublic={recipe.isPublic}></NewRecipeCard>
      }

    </div>
  )
}

export default Recipe;