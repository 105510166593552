import React, {useState} from "react";
import {Button, Checkbox, Label, Modal, TextInput} from "flowbite-react";
import axios from "axios";
import store from "store";
import {UserContext} from "../App";

interface Props {
    isOpen: boolean;
    closeModal: () => void;
}
export function LoginModal(props: Props) {
    const [emailErrorMessage, setEmailErrorMessage] = useState<string | null>(null);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState<string | null>(null);
    const [usernameErrorMessage, setUsernameErrorMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    // const user = React.useContext(UserContext);
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const email = event.currentTarget.email.value
        const password = event.currentTarget.password.value
        const isRemember = event.currentTarget.remember.checked
        const isValid = validateLogin(email, password);
        if(isValid) {
            login(email, password, isRemember);
        }
    }

    const login = async (email: string, password: string, isRemember: boolean) => {

        //Post login route

        try {
            const response = await axios.post('/api/users/login', {
                user: {
                    email,
                    password
                }
            });
            store.set('user', response.data.user);
            window.location.reload();
        } catch (error: any) {
            setEmailErrorMessage(error.response.data.msg);
            setPasswordErrorMessage(error.response.data.msg);
        }
    };

    const validateLogin = (email: string, password: string) => {
        let valid = true;
        setErrorMessage(null)
        setEmailErrorMessage(null)
        setPasswordErrorMessage(null)

        //Check email
        if(email === "") {
            setEmailErrorMessage("Email required!")
            valid = false;
        } else {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(!re.test(String(email).toLowerCase())) {
                setEmailErrorMessage("Invalid Email!")
                valid = false;
            }
        }
        if(password === "") {
            setPasswordErrorMessage("Password required!")
            valid = false;
        }
        return valid;
    };
    return (
        <Modal
            show={props.isOpen}
            size="md"
            popup={true}
            onClose={props.closeModal}
        >
            <Modal.Header />
            <Modal.Body>
                <form onSubmit={handleSubmit} className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
                    <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                        Sign in to Beef Universe
                    </h3>
                    <div>
                        <div className="mb-2 block">
                            <Label
                                htmlFor="email"
                                value="Your email"
                                color={emailErrorMessage ? "failure" : undefined}
                            />
                        </div>
                        <TextInput
                            id="email"
                            placeholder="bigbeefy@example.com"
                            color={emailErrorMessage ? "failure" : undefined}
                            helperText={ emailErrorMessage ? (<><span className="font-bold">Ope!{' '}</span>{emailErrorMessage}</>) : null}
                            // required={true}
                        />
                    </div>
                    <div>
                        <div className="mb-2 block">
                            <Label
                                htmlFor="password"
                                value="Your password"
                                color={passwordErrorMessage ? "failure" : undefined}
                            />
                        </div>
                        <TextInput
                            id="password"
                            type="password"
                            // required={true}
                            color={passwordErrorMessage ? "failure" : undefined}
                            helperText={passwordErrorMessage ? (<><span className="font-bold">Ope!{' '}</span>{passwordErrorMessage}</>) : null}

                        />
                    </div>
                    <div className="flex justify-between">
                        <div className="flex items-center gap-2">
                            <Checkbox id="remember" />
                            <Label htmlFor="remember">
                                Remember me
                            </Label>
                        </div>
                        <a
                            // href="/modal"
                            className="text-sm text-blue-700 hover:underline dark:text-blue-500"
                        >
                            Lost Password?
                        </a>
                    </div>
                    <div className="w-full">
                        <Button type="submit">
                            Log in to your account
                        </Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}
