import React, { useState } from 'react';
import RecipeModal from './RecipeModal';
import axios from "axios";

import store from "store";

function MealPrepMeal(props) {
  const token = "Token " + store.get('token');
  const [displayModal, setDisplayModal] = useState(false);

  function openModal() {
    setDisplayModal(true);
  }

  function closeModal() {
    setDisplayModal(false);
  }

  function deleteMeal() {
    if(window.confirm("Are you sure you want to delete this meal? (This cannot be undone)")) {
      axios.post('/api/meals/delete', 
      { 
        id: props.weekId,
        mealId: props.mealId,
        dayOfWeek: props.dayOfWeek
       },
      {
        headers: {Authorization: token},
          
      })
      .then(function() {
        window.location.reload();
      })
      .catch(function (error) {
        // handle error
      });
    }
  }

  function MealName() {
    if(props.recipeId) {
      return <div className="meal-name"><a href={'/recipe/'+props.recipeId}>{props.name}</a></div>
    }
    return <div className="meal-name">{props.name}</div>
  }

  return (
    <div>
      <MealName></MealName>
      <button className="btn green-footer-btn footer-btn"onClick={openModal}>Edit</button>
      <button className="btn red-footer-btn footer-btn" onClick={deleteMeal}>Delete</button>
      { displayModal &&
        <RecipeModal name={props.name} date={props.date} mealId={props.mealId} weekId={props.weekId} dayOfWeek={props.dayOfWeek} closeModal={closeModal}></RecipeModal>
      }
    </div>
  )
}

export default MealPrepMeal;