import React, { useState, useEffect, useRef } from 'react';

import axios from "axios";

import store from "store";

function LoginModal(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [isRememberMe, setIsRememberMe] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [showUsernameError, setShowUsernameError] = useState(false);
  const [usernameErrorMessage, setUsernameErrorMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const textInput = useRef(null);

  useEffect(() => {
    textInput.current?.focus();
  }, []);

  function closeModal() {
    if(showForgotPassword) {
      setShowForgotPassword(false);
    } else {
      props.closeModal();
    }
  }

  function handleEmailChange(email) {
    setEmail(email.target.value);
  }
  function handlePasswordChange(password) {
    setPassword(password.target.value);
  
  }
  function handleUsernameChange(username) {
    setUsername(username.target.value);
  }

  function handleRememberMeChange() {
    setIsRememberMe(!isRememberMe);
  }

  function validateLogin() {
    let valid = true;
    setShowEmailError(false)
    setShowPasswordError(false)
    setErrorMessage("")
    setEmailErrorMessage("")
    setPasswordErrorMessage("")

    //Check email
    if(email === "") {
      setShowEmailError(true);
      setEmailErrorMessage("Email required")
      valid = false;
    } else {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(!re.test(String(email).toLowerCase())) {
        setShowEmailError(true);
        setEmailErrorMessage("Invalid Email")
        valid = false;
      }
    }
    if(password === "") {
      setShowPasswordError(true);
      setPasswordErrorMessage("Password required")
      valid = false;
    }
    return valid;
  }

  function login(event) {
    event.preventDefault();
    const isValid = validateLogin();
    if(!isValid) {
      return;
    }
    
    //Post login route
    axios.post('/api/users/login', {
      user: {
        email,
        password,
        isRememberMe
      }
    })
    .then((response) => {
      // TODO: Not refresh page
      store.set('token', response.data.user.token)
      window.location.reload();
    })
    .catch(function (error) {
      // handle error
      if (error.response) {
        const errData = error.response.data
        // Request made and server responded
        console.log(errData.msg);
        setShowEmailError(true)
        setShowPasswordError(true)
        setErrorMessage(errData.msg)
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
    });
  }

  function forgotPassword() {
    setShowForgotPassword(true)
  }
  
  function rememberedPassword() {
    setShowForgotPassword(false)
  }

  function sendReset() {
    //Post forgotPassword route
    axios.post('/api/users/forgot', {
      user: {
        email,
      }
    })
    .then(() => {
      // alert(response.data.user.email)
      window.location.reload();
    })
    .catch(function (error) {
      // handle error
    });
  }

  function validateRegister() {
    let valid = true;
    setShowEmailError(false)
    setShowPasswordError(false)
    setShowUsernameError(false);
    setErrorMessage("")
    setEmailErrorMessage("")
    setPasswordErrorMessage("")
    setUsernameErrorMessage("")
    //Check email
    if(username === "") {
      setShowUsernameError(true);
      setUsernameErrorMessage("Username required")
      valid = false;
    }
    if(email === "") {
      setShowEmailError(true);
      setEmailErrorMessage("Email required")
      valid = false;
    } else {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(!re.test(String(email).toLowerCase())) {
        setShowEmailError(true);
        setEmailErrorMessage("Invalid Email")
        valid = false;
      }
    }
    if(password === "") {
      setShowPasswordError(true);
      setPasswordErrorMessage("Password required")
      valid = false;
    }
    return valid;
  }

  

  function register(event) {
    event.preventDefault();
    const isValid = validateRegister();
    if(!isValid) {
      return;
    }
    axios.post('/api/users/register', { 
      user: {
        email,
        password,
        username
      }
    })
    .then((response) => {
      store.set('token', response.data.user.token)
      window.location.reload();
    })
    .catch(function (error) {
      if (error.response) {
        const errData = error.response.data
        // Request made and server responded
        console.log(errData.msg);
        setShowEmailError(errData.emailError)
        setShowUsernameError(errData.usernameError)
        setErrorMessage(errData.msg)
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }    });


  }


  // function closeModal() {
  //   setShowModal(false);
  // }

  return (
    <div className="login-modal">
      <div className="blur" onClick={closeModal}></div>
      <div className="card modal-card login-modal-card">
        <form onSubmit={login} className={(props.modalType === 1 && !showForgotPassword) ? '' : 'hidden'}>
          <div className="login-modal-title">Welcome Back!</div>
          <div className={errorMessage !== "" ? "error-message" : 'hidden'}>{errorMessage}</div>
          <div className="login-row">
            <div className="login-modal-label">Email</div>
            <div><input className={"login-modal-input " + (showEmailError ? "error" : '')} type="email" value={email} onChange={handleEmailChange}/></div>
            <div className="input-error">{emailErrorMessage}</div>
          </div>
          <div className="login-row">
            <div className="login-modal-label">Password</div>
            <div><input className={"login-modal-input " + (showPasswordError ? "error" : '')} type="password" value={password} onChange={handlePasswordChange}/></div>
            <div className="input-error">{passwordErrorMessage}</div>
          </div>
          <div className="login-row remember-forgot">
            <div className="hidden remember-me">
              <input
              name="rememberMe"
              type="checkbox"
              checked={isRememberMe}
              onChange={handleRememberMeChange} />
              Remember me
            </div>
            <div className="forgot-password">
              <span className="link-underline" onClick={forgotPassword}>Forgot password</span>
            </div>
          </div>
          <button className="btn login-btn" onClick={login}>LOG IN</button>
        </form>
        <form onSubmit={register} className={props.modalType === 2 ? '' : 'hidden'}>
          <h2 className="login-modal-title">Register</h2>
          <div className={errorMessage !== "" ? "error-message" : 'hidden'}>{errorMessage}</div>
          <div className="login-row">
            <div className="login-modal-label">Username</div>
            <div><input className={"login-modal-input " + (showUsernameError ? "error" : '')} type="text" value={username} onChange={handleUsernameChange}/></div>
            <div className="input-error">{usernameErrorMessage}</div>
          </div>
          <div className="login-row">
            <div className="login-modal-label">Email</div>
            <div><input className={"login-modal-input " + (showEmailError ? "error" : '')} type="email" value={email} onChange={handleEmailChange}/></div>
            <div className="input-error">{emailErrorMessage}</div>
          </div>
          <div className="login-row">
            <div className="login-modal-label">Password</div>
            <div><input className={"login-modal-input " + (showPasswordError ? "error" : '')} type="password" value={password} onChange={handlePasswordChange}/></div>
            <div className="input-error">{passwordErrorMessage}</div>
          </div>
          <button className="btn login-btn" onClick={register}>Sign Up</button>
        </form>
        <div className={(props.modalType === 1 && showForgotPassword) ? '' : 'hidden'}>
          <div className="login-row">
            <div className="login-modal-label">Email</div>
            <div><input className="login-modal-input" type="email" value={email} onChange={handleEmailChange}/></div>
          </div>
          <div className="login-row remember-forgot">
            <div className="hidden remember-me">
              <input
              name="rememberMe"
              type="checkbox"
              checked={isRememberMe}
              onChange={handleRememberMeChange} />
              Remember me
            </div>
            <div className="forgot-password">
              <span className="link-underline" onClick={rememberedPassword}>Cancel</span>
            </div>
          </div>
          <button className="btn login-btn" onClick={sendReset}>Send reset</button>
        </div>
      </div>
    </div>
  )
}

export default LoginModal;