import React, {useState} from "react";
import {Button, Checkbox, Label, Modal, TextInput} from "flowbite-react";
import axios from "axios";
import store from "store";
import {UserContext} from "../App";

interface Props {
    isOpen: boolean;
    closeModal: () => void;
}
export function RegisterModal(props: Props) {
    const [emailErrorMessage, setEmailErrorMessage] = useState<string | null>(null);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState<string | null>(null);
    const [usernameErrorMessage, setUsernameErrorMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    // const user = React.useContext(UserContext);
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const username = event.currentTarget.username.value;
        const email = event.currentTarget.email.value;
        const password = event.currentTarget.password.value;
        const isValid = validateRegistration(username, email, password);
        if(isValid) {
            register(username, email, password);
        }
    }

    const validateRegistration = (username: string, email: string, password: string) => {
        let valid = true;
        setErrorMessage(null)
        setUsernameErrorMessage(null)
        setEmailErrorMessage(null)
        setPasswordErrorMessage(null)

        if(username === "") {
            setUsernameErrorMessage("Username required")
            valid = false;
        }
        if(email === "") {
            setEmailErrorMessage("Email required")
            valid = false;
        } else {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(!re.test(String(email).toLowerCase())) {
                setEmailErrorMessage("Invalid Email")
                valid = false;
            }
        }
        if(password === "") {
            setPasswordErrorMessage("Password required")
            valid = false;
        }
        return valid;
    };

    const register = async (username: string, email: string, password: string) => {

        //Post login route

        try {
            const response = await axios.post('/api/users/register', {
                user: {
                    username,
                    email,
                    password
                }
            });
            store.set('user', response.data.user);
            window.location.reload();
        } catch (error: any) {
            setEmailErrorMessage(error.response.data.msg);
            setPasswordErrorMessage(error.response.data.msg);
        }
    };


    return (
        <Modal
            show={props.isOpen}
            size="md"
            popup={true}
            onClose={props.closeModal}
        >
            <Modal.Header />
            <Modal.Body>
                <form onSubmit={handleSubmit} className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
                    <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                        Sign up for Beef Universe
                    </h3>
                    <div>
                        <div className="mb-2 block">
                            <Label
                                htmlFor="username"
                                value="Your username"
                                color={usernameErrorMessage ? "failure" : undefined}
                            />
                        </div>
                        <TextInput
                            id="username"
                            placeholder="big beefy"
                            color={usernameErrorMessage ? "failure" : undefined}
                            helperText={ usernameErrorMessage ? (<><span className="font-bold">Ope!{' '}</span>{usernameErrorMessage}</>) : null}
                            // required={true}
                        />
                    </div>
                    <div>
                        <div className="mb-2 block">
                            <Label
                                htmlFor="email"
                                value="Your email"
                                color={emailErrorMessage ? "failure" : undefined}
                            />
                        </div>
                        <TextInput
                            id="email"
                            placeholder="bigbeefy@example.com"
                            color={emailErrorMessage ? "failure" : undefined}
                            helperText={ emailErrorMessage ? (<><span className="font-bold">Ope!{' '}</span>{emailErrorMessage}</>) : null}
                            // required={true}
                        />
                    </div>
                    <div>
                        <div className="mb-2 block">
                            <Label
                                htmlFor="password"
                                value="Your password"
                                color={passwordErrorMessage ? "failure" : undefined}
                            />
                        </div>
                        <TextInput
                            id="password"
                            type="password"
                            // required={true}
                            color={passwordErrorMessage ? "failure" : undefined}
                            helperText={passwordErrorMessage ? (<><span className="font-bold">Ope!{' '}</span>{passwordErrorMessage}</>) : null}

                        />
                    </div>
                    <div className="w-full">
                        <Button type="submit">
                            Sign Up
                        </Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}
