import React, { useEffect, useState, useMemo, useCallback} from 'react';
// import { useParams } from 'react-router-dom';
import MiniRecipeCard from './MiniRecipeCard';
import { debounce } from 'lodash';

import store from "store";

import axios from "axios";

function Recipes(props) {

  const [recipes, setRecipes] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  let token = "Token " + store.get('token');
  const uri = '/api/recipes'
  
  const headlineText = props.filter === "myrecipes" ? "My Recipes" : "All Recipes"
  


  useEffect(() => {
    function getRecipes(filter) {
      axios.get(`${uri}/${filter}`, {
        headers: {Authorization: token}
      })
      .then((response) => {
        setRecipes(response.data.recipes);  
      })
      .catch(function (error) {
        // handle error
        console.log("error")
        console.log(error)
      });
    }
    getRecipes(props.filter);
  }, [token, props.filter])
  



  // const handleSearchQueryChange = event => {
  //   setSearchQuery(event.target.value);
  //   debounced(event.target.value);
  // };

  const handleSearchQueryChange = useCallback(e => {
    let { value } = e.target;
    setSearchQuery(value);
    searchRecipesDebounced(value);
}, []);

  const searchRecipes = query => {
    axios.post(`${uri}/search`, {
        query,
        scope: props.filter
      },
      {
        headers: {Authorization: token}
      })
      .then((response) => {
        setRecipes(response.data.recipes);  
      })
      .catch(function (error) {
        // handle error
        console.log("error")
        console.log(error)
      });
    // Todo: post a request to search for `value`
  }
  const searchRecipesDebounced = useMemo(() => debounce(searchRecipes, 500, true), [handleSearchQueryChange]);


  return (
    <div className="my-recipes-container">
      <div className="recipe-search-container">
        <div className="headline">{headlineText}</div>
        <input className="recipe-search" type="text" placeholder="search recipes" value={searchQuery} onChange={handleSearchQueryChange}/>
      </div>

      { recipes.map(function(recipe) {
        return <MiniRecipeCard key={recipe._id} recipeId={recipe._id} name={recipe.name} isPublic={recipe.isPublic}></MiniRecipeCard>
      })}
    </div>
  )
}

export default Recipes;