import React from 'react';
import Modal from 'react-modal';
import axios from "axios";

import store from "store";

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    zIndex                : '101'
  }
};

Modal.setAppElement('#root');

class LegacySidebar extends React.Component {
  constructor(props){
    super(props);
    this.token = "Token " + store.get('token');
    this.state = {
      userObj: "",
      showChangePasswordForm: false,
      newUsername: "",
      newBudget: "",
      email: "",
      password: "",
      newPassword: "",
      modalIsOpen: false,
      menuIsOpen: false
    }
    this.change = this.change.bind(this);
    this.changeBudget = this.changeBudget.bind(this);
    this.toggleChangePasswordForm = this.toggleChangePasswordForm.bind(this);
    this.toggleChangeBudgetForm = this.toggleChangeBudgetForm.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
    this.handleNewBudgetChange = this.handleNewBudgetChange.bind(this);
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.logout = this.logout.bind(this);

  }

  toggleChangePasswordForm() {
    this.setState(state => ({
      modalIsOpen: !state.showChangePasswordForm,
      showChangePasswordForm: !state.showChangePasswordForm,
    }));
  }
  
  toggleChangeBudgetForm() {
    this.setState(state => ({
      modalIsOpen: !state.showChangeBudgetForm,
      showChangeBudgetForm: !state.showChangeBudgetForm,
    }));
  }
  
  handlePasswordChange(event) {
    this.setState({password: event.target.value});
  }
  handleNewPasswordChange(event) {
    this.setState({newPassword: event.target.value});
  }
  handleNewBudgetChange(event) {
    this.setState({newBudget: event.target.value});
  }

  change() {
    //Post change route
    axios.post('/api/users/change', 
    {
      "token": this.token,
      "oldPassword": this.state.password,
      "newPassword": this.state.newPassword},
    {
      headers: {Authorization: this.token},
        
    })
    .then((response) => {
      //Navigate home
      this.setState({ modalIsOpen: false})
    })
    .catch(function (error) {
      // handle error
    });
  }

  changeBudget() {
    //Post change route
    axios.post('/api/users/changebudget', 
    {
      "newBudget": this.state.newBudget},
    {
      headers: {Authorization: this.token},
        
    })
    .then((response) => {
      //Navigate home
      window.location.reload();
    })
    .catch(function (error) {
      // handle error
    });
  }
  openModal() {
    this.setState({modalIsOpen: true});
  }
  openMenu() {
    this.setState({menuIsOpen: true});
  }
 
  afterOpenModal() {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
  }
 
  closeModal() {
    this.setState({
      modalIsOpen: false,
      showChangePasswordForm: false,
      showChangeBudgetForm: false,
    });
  }

  closeMenu() {
    this.setState({menuIsOpen: false});
  }

  logout() {
    store.remove('token');
    window.location.reload();
  }
  
  foodBudget() {
    window.location.href ="/budget";
  }

  mealPlanning() {
    window.location.href ="/mealprep";
  }

  myRecipes() {
    window.location.href ="/myrecipes";
  }

  findRecipes() {
    window.location.href ="/recipes";
  }

  newRecipe() {
    window.location.href ="/recipe/new";
  }

  render() {
    return (
        <div>
          <div className={"sidebar-container " + (this.state.menuIsOpen ? '' : 'hidden')}>
            <Modal
              isOpen={this.state.modalIsOpen}
              onAfterOpen={this.afterOpenModal}
              onRequestClose={this.closeModal}
              style={customStyles}
              contentLabel="Login Modal"
            >
              <div>
                <div className={this.state.showChangePasswordForm ? '' : 'hidden'}>
                  <h2 className="change-modal">Change password</h2>
                  <div className="login-row">Current password: <input type="password" value={this.state.password} onChange={this.handlePasswordChange}/></div>
                  <div className="login-row">New password: <input type="password" value={this.state.newPassword} onChange={this.handleNewPasswordChange}/></div>
                  <button className="btn" onClick={this.change}>submit</button>
                  <button className="btn" onClick={this.closeModal}>close</button>
                </div>
                <div className={this.state.showChangeBudgetForm ? '' : 'hidden'}>
                  <h2 className="change-modal">Change weekly budget</h2>
                  <div className="login-row">New budget: <input type="text" value={this.state.newBudget} onChange={this.handleNewBudgetChange}/></div>
                  <button className="btn" onClick={this.changeBudget}>submit</button>
                  <button className="btn" onClick={this.closeModal}>close</button>
                </div>
              </div> 
              {/* <LoginRegisterModal></LoginRegisterModal> */}
            </Modal>
            <div className="blur" onClick={this.closeMenu}></div>
            <div className={"sidebar " + (this.state.menuIsOpen ? '' : 'hidden')}>
              <div className="hamburger-item top-bun link close-sidebar" onClick={this.closeMenu}>Close</div>
              <div className={this.props.loggedIn ? 'hidden' : ''}>
                <div className="hamburger-item link" onClick={this.props.register}>Create Account</div>
                <div className="hamburger-item bottom-bun link" onClick={this.props.login}>Log In</div>
              </div>
              <div className={this.props.loggedIn ? '' : 'hidden'}>
                {/* <div className="hamburger-item link">Profile</div> */}
                <div className="hamburger-item link" onClick={this.foodBudget}>Food Budget</div>
                <div className="hamburger-item link" onClick={this.mealPlanning}>Meal Planning</div>
                <div className="hamburger-item link" onClick={this.findRecipes}>Find Recipes</div>
                <div className="hamburger-item link" onClick={this.myRecipes}>My Recipes</div>
                <div className="hamburger-item link" onClick={this.newRecipe}>New Recipe</div>
                <div className="hamburger-item link" onClick={this.toggleChangePasswordForm}>Change Password</div>
                {/* <div className="hamburger-item link" onClick={this.toggleChangeBudgetForm}>Change Weekly Limit</div> */}
                <div className="hamburger-item bottom-bun link" onClick={this.logout}>Logout</div>
              </div>
            </div>
          </div>
          <div className={"sidebar-container " + (this.state.menuIsOpen ? 'hidden' : '')}>
            <div className="hamburger link" onClick={this.openMenu}>
              <div className="slice"></div>
              <div className="slice"></div>
              <div className="slice"></div>
            </div>
          </div>
        </div>
        
    )
  }

  

  
}

export default LegacySidebar;