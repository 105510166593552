import React from "react";
import {SidebarItem} from "./SidebarItem";
import {CalculatorIcon} from "../icons/CalculatorIcon";
import {ComputerDesktopIcon} from "../icons/ComputerDesktopIcon";
import {CalendarDaysIcon} from "../icons/CalendarDaysIcon";
import {MagnifyingGlassIcon} from "../icons/MagnifyingGlassIcon";
import {BookmarkIcon} from "../icons/BookmarkIcon";
import {DocumentPlusIcon} from "../icons/DocumentPlusIcon";
import {PencilSquareIcon} from "../icons/PencilSquareIcon";
import {MagnifyingGlassCircleIcon} from "../icons/MagnifyingGlassCircleIcon";
import {BookmarkSquareIcon} from "../icons/BookmarkSquareIcon";

export function Sidebar() {
    return (
        <aside id="logo-sidebar"
               className="fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform -translate-x-full bg-white border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700"
               aria-label="Sidebar">
            <div className="h-full px-3 pb-4 overflow-y-auto bg-white dark:bg-gray-800">
                <ul className="space-y-2">
                    <SidebarItem name="Dashboard" link="/" icon={<ComputerDesktopIcon/>}/>
                    <SidebarItem name="Food Budget" link="/budget" icon={<CalculatorIcon/>}/>
                    <SidebarItem name="Meal Prep" link="/mealprep" icon={<CalendarDaysIcon/>}/>
                    <SidebarItem name="Find Recipes" link="/recipes" icon={<MagnifyingGlassCircleIcon/>}/>
                    <SidebarItem name="My Recipes" link="/myrecipes" icon={<BookmarkSquareIcon/>}/>
                    <SidebarItem name="Add Recipe" link="/recipe/new" icon={<PencilSquareIcon/>}/>
                </ul>
            </div>
        </aside>
    )
}