import React from 'react';

function MiniRecipeCard(props) {

  return (
    <div className="card mini-recipe-card" >
      <h3>{props.name}</h3>
      <div className="right">
        {props.isPublic ? "(public)" : "(private)"}
      </div>
      <a href={'/recipe/'+props.recipeId}>View Recipe</a>
    </div>
  )
}

export default MiniRecipeCard;