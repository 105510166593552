import React from 'react';


class WeekPicker extends React.Component {
  constructor(props){
    super(props);

    this.previousWeek = this.previousWeek.bind(this);
    this.nextWeek = this.nextWeek.bind(this);


  }

  previousWeek() {
    this.props.updateParent(1)
  }

  nextWeek() {
    this.props.updateParent(-1)
  }

  
  render() {
    return (
        <div className="week-picker">
          <input type='button' className="btn week-btn previous-btn" value='previous week' onClick={this.previousWeek}/>
          <input type='button' className="btn week-btn next-btn" value='next week' onClick={this.nextWeek}/>
        </div>
    )
  }

  

  
}

export default WeekPicker;