import React from 'react';
// import logo from './logo.svg';
import './App.css';
import FoodBudget from './components/FoodBudget';
import Dashboard from './components/Dashboard';
import MealPrep from './components/MealPrep';
import Recipes from './components/Recipes';
import Header from './components/Header';
import NewRecipe from './components/NewRecipe';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import Recipe from './components/Recipe';
import ResetPassword from './components/ResetPassword';

import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import {DashboardScreen} from "./dashboard/DashboardScreen";
import {Navbar} from "./sidebar/Navbar";
import {Sidebar} from "./sidebar/Sidebar";
import { PageContent } from './sidebar/PageContent';
import store from "store";
import {FoodBudgetScreen} from "./food-budget/FoodBudgetScreen";
export const UserContext = React.createContext(store.get('user'));

export function App() {

    const [user, setUser] = React.useState<any>(null);

    return (
        <Router>
            <div className="App">
                <Navbar/>
                <Sidebar/>
                <PageContent>
                    <Routes>
                        <Route path="/reset/:token" element={<ResetPassword/>}/>
                        <Route path='/privacy' element={<Privacy/>}/>
                        <Route path='/terms' element={<Terms/>}/>
                        <Route path="/mealprep" element={<MealPrep/>}/>
                        <Route path="/recipe/new" element={<NewRecipe/>}/>
                        <Route path="/recipe/:recipeId" element={<Recipe/>}/>
                        <Route path="/recipes" element={<Recipes filter="all"/>} />
                        <Route path="/myrecipes" element={<Recipes filter="myrecipes"/>}/>
                        <Route path='/budget/all' element={<FoodBudgetScreen user={user} filter="all"/>}/>
                        <Route path='/budget' element={<FoodBudgetScreen user={user} filter="0"/>}/>
                        <Route path='/dashboard' element={<Dashboard user={user} filter="0"/>}/>
                        <Route path='/' element={<DashboardScreen/>}/>
                    </Routes>
                </PageContent>
            </div>
        </Router>
    )
}
