import React, { useState, useEffect, useRef } from 'react';
import DatePicker from "react-datepicker";

function MealPlannerDatePickerModal(props) {

  const [date, setDate] = useState(new Date());

  const textInput = useRef(null);

  useEffect(() => {
    textInput.current?.focus();
  }, []);

  function closeParentModal() {
    props.closeModal();
  }

  function saveMeal() {
    props.submitDate(date)
  }

  function handleDateChange(changedDate) {
    setDate(changedDate)
  }

  return (
    <div className="mealplan-modal">
      <div className="blur" onClick={closeParentModal}></div>
      <div className="card modal-card">
        <div>
          <span>Add meal to date:</span>
        </div>
        <DatePicker selected={date} onChange={handleDateChange} />
        <div>
          <button className="btn" onClick={saveMeal}>Save</button>
          <button className="btn" onClick={closeParentModal}>Cancel</button>
        </div>
      </div>
    </div>
  )
}

export default MealPlannerDatePickerModal;