import React, { useEffect, useState} from 'react';
import BudgetDashboardCard from './BudgetDashboardCard';
import MealsDashboardCard from './MealsDashboardCard';

import store from "store";

import axios from "axios";

function DashboardCard(props) {

  const [moneySpent, setMoneySpent] = useState(0);
  const [week, setWeek] = useState();

  let token = "Token " + store.get('token');

  useEffect(() => {
    function getMoneyLeft() {
      axios.get('/api/purchases/0', {
        headers: {Authorization: token}
      })
      .then((response) => {
        setMoneySpent(response.data.moneySpent)
      })
      .catch(function (error) {
        // handle error
        console.log("error")
        console.log(error)
      });
    }
    function getMeals() {
      axios.get('/api/meals', {
        headers: {Authorization: token}
      })
      .then((response) => {
        setWeek(response.data.week)
      })
      .catch(function (error) {
        // handle error
        console.log("error")
        console.log(error)
      });
    }
    getMoneyLeft()
    getMeals()

  }, [token])

  return (
    <div className="dashboard-cards flex-cards">
      {props.user &&
        <BudgetDashboardCard moneyRemaining={props.user.moneyRemaining - moneySpent}/>
      }
      <MealsDashboardCard week={week}/>
    </div>
  )
}

export default DashboardCard;