import React from 'react';
import "react-datepicker/dist/react-datepicker.css";
import GroceryList from './GroceryList';
import MealPlannerDatePickerModal from './MealPlannerDatePickerModal';


import axios from "axios";

import store from "store";

class RecipeCard extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      showGroceryList: false,
      ingredients: this.props.ingredients,
      steps: this.props.steps,
      showModal: false,
      favorites: this.props.favorites
    }

    // this.showEditForm = this.showEditForm.bind(this);
    this.showGroceryList = this.showGroceryList.bind(this);
    this.closeGroceryList = this.closeGroceryList.bind(this);
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.submitDate = this.submitDate.bind(this);
    this.favoriteRecipe = this.favoriteRecipe.bind(this);
    this.unfavoriteRecipe = this.unfavoriteRecipe.bind(this);
    this.token = "Token " + store.get('token');

  }
  
  componentDidUpdate() {
    if(this.props.ingredients && !this.state.ingredients) {
      this.setState({
        ingredients: this.props.ingredients
      })
    }
    if(this.props.steps && !this.state.steps) {
      this.setState({
        steps: this.props.steps
      })
    }
  }

  showGroceryList() {
    this.setState({showGroceryList: true})
  }
  closeGroceryList() {
    this.setState({showGroceryList: false})
  }

  handleIngredientCheckbox(i) {
    let ingredients = this.state.ingredients;
    ingredients[i].checked = !ingredients[i].checked;
    this.setState({ingredients})
  }

  handleStepCheckbox(i) {
    let steps = this.state.steps;
    steps[i].checked = !steps[i].checked;
    this.setState({steps})
  }

  showModal() {
    this.setState({showModal: true})
  }
  closeModal() {
    this.setState({showModal: false})
  }
  submitDate(date) {
    axios.post('/api/meals/add', 
    {
      name: this.props.name,
      date,
      recipeId: this.props.recipeId
    },
    {
      headers: {Authorization: this.token},
        
    })
    .then(() => {
      // TODO: Not refresh page
      window.location.reload();
    })
    .catch(function () {
      // handle error
    });
  }

  favoriteRecipe() {
    axios.post('/api/recipes/favorite', 
    {
      recipeId: this.props.recipeId
    },
    {
      headers: {Authorization: this.token},
        
    })
    .then(() => {
      // TODO: Not refresh page
      window.location.reload();
    })
    .catch(function () {
      // handle error
    });
  }

  unfavoriteRecipe() {
    axios.post('/api/recipes/unfavorite', 
    {
      recipeId: this.props.recipeId
    },
    {
      headers: {Authorization: this.token},
        
    })
    .then(() => {
      // TODO: Not refresh page
      window.location.reload();
    })
    .catch(function () {
      // handle error
    });
  }

  favoriteButtons() {
    if(this.props.isFavorited) {
      return ( 
        <div className="right">
          <div className="favorites-badge">{this.state.favorites || this.props.favorites}</div>
          <button className="btn footer-btn" onClick={this.unfavoriteRecipe}>Unfavorite</button>
        </div>
      )
    } else {
      return (
        <div className="right">
          <div className="favorites-badge">{this.state.favorites || this.props.favorites}</div>
          <button className="btn footer-btn" onClick={this.favoriteRecipe}>Favorite</button>
        </div>
      )
    }
  }

  render() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    return (
        <div className="card recipe-card" >
          {this.favoriteButtons()}
          <button className="btn footer-btn" onClick={this.showModal}>Add To Meal Planner</button>
          <button className="btn footer-btn" onClick={this.props.showEditForm}>Edit</button>
          <h3>{this.props.name}</h3>
          <ul>
          {this.state.ingredients && 
          this.state.ingredients.map(function(ingredient, i) {
            let uom = ingredient.uom;
            if (!uom || uom === "each") {
              uom = "";
            }
            return (
            <div key={ingredient.name}>
              <label className="checkbox-label">
                <input className="custom-checkbox" type="checkbox" checked={ingredient.checked ? 'checked' : ''} onChange={that.handleIngredientCheckbox.bind(that, i)}></input>
                <span className={ingredient.checked ? 'recipe-completed' : ''}>{(ingredient.quantity || "") + " " + uom + " " + ingredient.name}</span>
              </label>
            </div>
            )
          })}
          </ul>
          {this.state.ingredients &&
            <button className="btn footer-btn" onClick={this.showGroceryList}>Make grocery list</button>
          }
          <div>
          {this.state.steps && 
          this.state.steps.map(function(step, i) {
            return (
              <div key={i} className="recipe-step">
                <span className="step-text">Step {i+1}<br/></span>
                <label className="checkbox-label">
                  <input className="custom-checkbox" type="checkbox" checked={step.checked ? 'checked' : ''} onChange={that.handleStepCheckbox.bind(that, i)}></input>
                  <span className={step.checked ? 'recipe-completed step-content' : 'step-content'}>{step.text}</span>
                </label>
                
              </div>)
          })}
          </div>
          <div className={this.state.showGroceryList ? '' : 'hidden'}>
            <GroceryList ingredients={this.state.ingredients} closeGroceryList={this.closeGroceryList}></GroceryList>
          </div>
          {this.state.showModal &&
            <MealPlannerDatePickerModal closeModal={this.closeModal} submitDate={this.submitDate}></MealPlannerDatePickerModal>
          }
        </div>
    )
  }

  

  
}

export default RecipeCard;