import React from 'react';


function MealsDashboardCard(props) {

  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  function goToMealPlanning() {
    window.location.href ="/mealprep";
  }

  function createMeals(meals) {
    if(meals.length === 0) {
      return <div className="no-meal">No meals scheduled</div>
    }
    return (
      <div>
        {meals.map(function(meal) {
          return <div key={meal._id}>{meal.name}</div>
        })}
      
      </div>
    )
  }

  return (
      <div className="dashboard-card flex-card meal-planning-preview card">
        <button className="btn" onClick={goToMealPlanning}>Go to Meal Planner</button>
        <div>Meals this week:</div>
        {days.map(function(day) {
          if(props.week) {
            const meals = props.week[day.toLowerCase()]
            // console.log("meals", props.week[day.toLowerCase()])
            // const meals = .map(function(meal) {
            //   return meal
            // })
            return (
              <div key={day}>
                <h2>{day+ ": "}</h2>
                {createMeals(meals)}
              </div>
            )
          }
          return <div key={day}></div>
        })}
      </div>
  )
}

export default MealsDashboardCard;